<template>
  <div>
    <van-field v-model="areaName" :rules="[{ required: true, message: $t('public.pleaseSelect')+$t(placeholder) }]" :name="name" clickable :class="{'has-val':String(areaName).length}" class="form-item is-select" readonly :placeholder="$t(placeholder)" @click="openShowPopup" />
    <van-popup v-model="showPopup" :style="{ height: '60%' }" position="bottom" @close="$emit('update:isFocusPoint', false)" @open="$emit('update:isFocusPoint', true)">
      <van-area v-if="type === 'area'" :cancel-button-text="$t('public.cancleBtn')" :confirm-button-text="$t('public.confirmBtn')" :value="value" :title="title" :columns-num="3" :area-list="areaList" @confirm="confirm" />
      <van-cascader v-if="type === 'cascader'" ref="cascader" v-model="cascaderValue" :placeholder="cascaderPlaceholder" :title="$t(title)" :options="areaListArry" @close="showPopup = false" @change="onChange" @finish="onFinish" />
    </van-popup>
  </div>
</template>

<script>
import { areaList, areaListArry } from '@/utils/area-data'
import { getProvince, getCity, getCounty } from '@/api/axios/index'
export default {
  name: 'PopupArea',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    /**
     * type 省市区类型
     * 可选值 area cascader
     */
    type: {
      type: String,
      default: 'cascader'
    },
    placeholder: {
      type: [String, Number],
      default: () => {
        return 'baseInfo.liveArea'
      }
    },
    name: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      areaList,
      areaListArry,
      showPopup: false,
      showValue: '',
      areaName: '',
      cascaderValue: '',
      cascaderPlaceholder: this.$t('baseInfo.liveAreaArry')[0]
    }
  },
  watch: {
    value: {
      handler() {
        if (this.type === 'area') {
          this.areaName = this.getCityVal(this.value)
        } else {
          this.areaName = this.value
        }
      },
      immediate: true
    }
  },

  created() {
    // this.getCounty()
  },

  methods: {
    openShowPopup() {
      // if (this.disabled) {
      //   this.$toast.fail(this.$t('baseInfo.perfectInfo'))
      //   return
      // }
      this.showPopup = !this.showPopup
    },
    // area
    confirm(valArry) {
      const areaCode = valArry[1].code
      this.areaName = this.getCityVal(areaCode)
      this.showPopup = false
      this.$emit('input', areaCode)
      this.$emit('confirm', areaCode, this.areaName)
    },

    // cascader Change
    onChange({ value, selectedOptions, tabIndex }) {
      this.cascaderPlaceholder = this.$t('baseInfo.liveAreaArry')[tabIndex + 1]
      // if (tabIndex + '' === '0') {
      //   this.getProvince(value, selectedOptions)
      // } else if (tabIndex + '' === '1') {
      //   console.log(selectedOptions)
      //   this.getCity(value, selectedOptions)
      // }
      this.$emit('onChange', value)
    },

    // cascader Finish
    onFinish(data) {
      const selectedOptions = data?.selectedOptions
      if (selectedOptions.length === 3) {
        this.areaName = selectedOptions[0].text + '/' + selectedOptions[1].text + '/' + selectedOptions[2].text
      } else {
        this.areaName = selectedOptions[0].text + '/' + selectedOptions[1].text
      }

      this.$emit('input', this.areaName)
      // (区/省/市)
      this.$emit('onFinish', selectedOptions[0].text, selectedOptions[1].text, selectedOptions[2]?.text || '')
      this.showPopup = false
    },

    async getCounty() {
      try {
        const { data } = await getCounty()
        this.areaListArry = data?.county
        // this.areaListArry.forEach(item => {
        //   item.children = this.getProvince(item.county_id, item)
        //   item.text = item.county_name
        //   item.value = item.county_id
        // })
        for (let i = 0; i < this.areaListArry.length; i++) {
          this.areaListArry[i].text = this.areaListArry[i].county_name
          this.areaListArry[i].value = this.areaListArry[i].county_id
          this.areaListArry[i].children = await this.getProvince(this.areaListArry[i].county_id, this.areaListArry[i])
        }
        console.log(JSON.stringify(this.areaListArry))
      } catch (err) {
        console.log(err)
      }
    },
    async getProvince(id, item) {
      console.log(id)
      try {
        // this.firstIndex = this.areaListArry.findIndex(item => { return item.value === id })
        const { data } = await getProvince({ county_id: id })
        const dataList = data?.province || []
        // dataList.forEach(item => {
        //   item.children = []
        //   item.text = item.provinces_name
        //   item.value = item.provinces_id
        // })
        // selectedOptions.forEach((item, index) => {
        //   if (item.value === id) {
        //     item.children = dataList || []
        //     this.$set(item, index, item)
        //   }
        // })

        for (let i = 0; i < dataList.length; i++) {
          dataList[i].text = dataList[i].provinces_name
          dataList[i].value = dataList[i].provinces_id
          const list = await this.getCity(dataList[i].provinces_id, dataList[i])
          if (list)dataList[i].children = list
        }
        return dataList.length ? dataList : [item]
        // return dataList
        // this.areaListArry[this.firstIndex].children = dataList
        // this.$set(this.areaListArry, this.firstIndex, this.areaListArry[this.firstIndex])
        // console.log(data)
      } catch (err) {
        console.log(err)
      }
    },
    async getCity(id, item) {
      try {
        // const index = this.areaListArry[this.firstIndex].children.findIndex(item => { return item.value === id })
        const { data } = await getCity({ province_id: id })
        const dataList = data || []
        dataList.forEach(item => {
          item.text = item.city_name
          item.value = item.city_id
        })
        return dataList.length ? dataList : null
        // selectedOptions.forEach((item, index) => {
        //   if (item.value === id) {
        //     item.children = dataList.length ? dataList : null
        //   }
        //   this.$refs.cascader.updateTabs()
        // })
        // eslint-disable-next-line require-atomic-updates
        // selectedOptions[1].children = dataList || []
        // this.$set(this.areaListArry[this.firstIndex].children[index], 'children', dataList)
      } catch (err) {
        console.log(err)
      }
    }

  }
}
</script>
<style lang="scss" scoped>
:deep(.van-picker__toolbar){
  height: 80px;
  .van-picker__cancel,
  .van-picker__confirm,
  .van-picker__title{
    font-size: 30px;
    height: 80px;
    line-height: 80px;
  }
}
:deep(.van-picker-column__item){
  font-size: 26px;
}

:deep(.van-cascader){
  .van-cascader__header{
    height: auto;
    padding: 40px;
    padding-bottom: 26px;
    .van-cascader__title{
      font-size: 32px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #1F1F39;
      line-height: 62px;
    }
    .van-cascader__close-icon{
      font-size: 32px;
      color: #000;
    }
  }
  .van-tabs__nav{
    padding-left: 30px;
    .van-cascader__tab{
      font-size: 28px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #1F1F39;
      line-height: 44px;
    }
    .van-tabs__line{
      width: 40px;
      height: 5px;
      background-color: #3D5CFF;
    }
  }
  .van-cascader__options{
    height: 600px;
    .van-cascader__option{
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #1F1F39;
      line-height: 40px;
      margin-bottom: 20px;
      padding-left: 40px;
    }
    .van-cascader__option--selected{
      color:#3D5CFFFF;
      .van-cascader__selected-icon{
        font-size: 30px;
      }
    }
  }

}
</style>
