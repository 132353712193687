<template>
  <div>
    <!-- 上班族 -->
    <!-- :disabled="!psubmitFlag" -->
    <!-- :disabled="!dataForm.years_working" -->
    <!-- :disabled="!dataForm.profession" -->
    <!--  :disabled="!dataForm.monthly_income" -->
    <!-- :disabled="!dataForm.company_name" -->
    <!-- :disabled="!dataForm.company_phone" -->
    <!--  :disabled="!dataForm.company_region"  -->
    <!--  :disabled="!dataForm.company_address" -->
    <!-- :disabled="!dataForm.salary_way" -->
    
    <popu-action-select @click="confirmClickNum('years_working','years_working_num')" v-model="dataForm.years_working"  name="years_working" :placeholder="$t('baseInfo.workOrBusinessYears')[0]" :data-list="$t('baseInfo.workOrBusinessYearsList')" :is-focus-point.sync="isFocusPoint" />
    <popu-action-select @click="confirmClickNum('profession','profession_num')" v-model="dataForm.profession"  name="profession" placeholder="baseInfo.companyIndustry" :data-list="$t('baseInfo.industryList')" :is-focus-point.sync="isFocusPoint" />
    <popu-action-select  @click="confirmClickNum('monthly_income','monthly_income_num')" name="monthly_income" v-model="dataForm.monthly_income"  placeholder="baseInfo.incomeLevel" :data-list="$t('baseInfo.incomeLevelList')" :is-focus-point.sync="isFocusPoint" />
    <van-field  @click="confirmClickNum('company_name','company_name_num')" v-model="dataForm.company_name" name="companyName" :rules="rules.CompanyName" type="text" class="form-item" :placeholder="$t('baseInfo.companyname')" />
    <van-field @click="confirmClickNum('company_phone','company_phone_num')" v-model="dataForm.company_phone" :maxlength="maxLength"  :rules="rules.companyPhone" name="companyPhone" type="tel" class="form-item" :placeholder="$t('baseInfo.companyTel')" />
    <popup-area @click="confirmClickNum('company_region','company_region_num')" v-model="dataForm.company_region" name="company_region" title="baseInfo.companyArea"  placeholder="baseInfo.companyArea" :is-focus-point.sync="isFocusPoint" @onFinish="areaFinish" />
    <van-field @click="confirmClickNum('company_address','company_address_num')" v-model="dataForm.company_address" name="companyAddress" :rules="rules.CompanyAddress" type="text" class="form-item" :placeholder="$t('baseInfo.companyDetailAddres')" />
    <popu-action-select @click="confirmClickNum('salary_way','salary_way_num')" v-model="dataForm.salary_way" placeholder="baseInfo.salaryType" name="salary_way" :data-list="$t('baseInfo.payDayTypeList')" :is-focus-point.sync="isFocusPoint" />
    <popu-action-select @click="confirmClickNum('pay_day','pay_day_num')" v-model="dataForm.pay_day"  name="pay_day" placeholder="baseInfo.payDay" :data-list="paydayList" :is-focus-point.sync="isFocusPoint" />
  </div>
</template>

<script>
import rules from '@/utils/rules'
import perfectInformation from '../../perfectInformation'
import { validWhatsapp } from '@/utils/validate'
import PopupArea from '@/components/popupArea'

export default {
  name: 'OfficeWorker',
  components: { PopupArea },
  mixins: [perfectInformation],
  props: {
    psubmitFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules,
      submitFlag: false,
      paydayList: this.$t('baseInfo.weekList'),
      isGetData: false,
      dataForm: {
        years_working: '', // 工作年限
        profession: '', // 所属行业
        monthly_income: '', // 月收入
        company_region: '',
        company_name: '', // 公司名称
        company_phone: '', // 公司电话
        company_provinces: '', // 公司所在地区省份
        company_city: '', // 公司所在市/县
        company_county: '', // 公司所在市区
        company_address: '', // 公司详细地址
        salary_way: '', // 发薪方式
        pay_day: '' // 发薪日
      }
    }
  },
  computed: {
    maxLength() {
      return 13
      // if (this.dataForm.company_phone.indexOf('09') === 0) {
      //   return 11
      // } else if (this.dataForm.company_phone.indexOf('63') === 0) {
      //   return 12
      // } else {
      //   return 10
      // }
    }
  },

  watch: {
    'dataForm.salary_way': {
      handler(v1, v2) {
        if (v1 !== v2) this.paydayList = this.getPaydayList(v1)
      }
    }
  },
  methods: {
    submit() {

    },
    getPaydayList(val) {
      if (!val) return
      let dayList = []
      if (!this.isGetData) this.dataForm.pay_day = ''
      this.isGetData = false
      const item = this.$t('baseInfo.payDayTypeList').find(item => { return item.name + '' === val + '' })
      dayList = this.$t(`baseInfo.${Number(item.val) < 3 ? 'weekList' : 'payDayList'}`)
      return dayList
    },
    validWhatsapp() {
      if (!validWhatsapp(this.dataForm.company_phone)) {
        this.dataForm.company_phone = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-bottom: 300px;
}
</style>
