import * as validator from '@/utils/validate';
import i18n from '@/i18n';
export default {
    UserName: [
        { required: true, message: i18n.t('rules.nameTest') },
        { validator: validator.validUserName, message: i18n.t('rules.nameTest') }
    ],
    idCard: [
        { required: true, message: i18n.t('rules.idCardTest') },
        { validator: validator.valididCard, message: i18n.t('rules.idCardTest') }
    ],
    RpcNumber: [
        { required: true, message: i18n.t('rules.rfcTest') },
        { validator: validator.validRfc, message: i18n.t('rules.rfcTest') }
    ],
    ChildNum: [{ required: true, message: i18n.t('rules.childTest') }],
    LiveAddress: [
        { required: true, message: i18n.t('rules.liveAddressTest') },
        { validator: validator.validSpecialStr, message: i18n.t('rules.liveAddressTest') }
    ],
    Email: [
        { required: true, message: i18n.t('rules.emailTest') },
        { validator: validator.validEmail, message: i18n.t('rules.emailTest') }
    ],
    WhatsApp: [
        { required: true, message: i18n.t('rules.whatsappTest') },
        { validator: validator.validWhatsapp, message: i18n.t('rules.whatsappTest') }
    ],
    age: [
        { required: true, message: i18n.t('cupones.qingshurunindeAge') },
        { validator: validator.validAge, message: i18n.t('cupones.qingshurunindeAge') }
    ],
    companyPhone: [
        { required: true, message: i18n.t('rules.whatsappTest') },
        { validator: validator.validCompanyPhone, message: i18n.t('rules.whatsappTest') }
    ],
    RelationshipName: [
        { required: true, message: i18n.t('rules.nameTest') },
        { validator: validator.validUserName, message: i18n.t('rules.nameTest') }
    ],
    RelationshipPhone: [
        { required: true, message: i18n.t('rules.iphoneTest') },
        { validator: validator.RelationshipPhone, message: i18n.t('rules.nameTest') }
    ],
    CompanyName: [
        { required: true, message: i18n.t('rules.companyNameTest') },
        { validator: validator.validSpecialStr, message: i18n.t('rules.companyNameTest') }
    ],
    CompanyAddress: [
        { required: true, message: i18n.t('rules.companyAddressTest') },
        { validator: validator.validSpecialStr, message: i18n.t('rules.companyAddressTest') }
    ],
    SchoolName: [
        { required: true, message: i18n.t('rules.schoolNameTest') },
        { validator: validator.validSpecialStr, message: i18n.t('rules.schoolNameTest') }
    ],
    ProfessionalName: [
        { required: true, message: i18n.t('rules.professionalNameTest') },
        { validator: validator.validSpecialStr, message: i18n.t('rules.schoolNameTest') }
    ],
    BankNumber: [{ required: true, message: i18n.t('rules.bankNumberTset') }]
};
